<template>
  <FocusTrap>
    <div  id="vaultpayment_card" class="card">

    <div class="card-header header-elements-inline" style="background-color: whitesmoke;padding: 10px;" >
      <h5 v-if="voucher.id == 0" class="card-title">Vault Payment Creation</h5>
      <h5 v-if="voucher.id > 0" class="card-title"> Doc No: {{voucher.doc_no}}  Vault Payment Updation</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload" @click="loadData" ></a>
          <a class="list-icons-item" data-action="remove" @click="closeThis"></a>
        </div>
      </div>
    </div>

    <div class="card-body">

      <div class="row">

        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Paid To</label>
            <select id="cmbledger" class="form-control select" autofocus="" required="" v-if="voucher" v-model="voucher.ledger_id" >
              <option v-for="ledger in ledgers" v-bind:value="ledger.id">
                {{ ledger.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-7">

        </div>

        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Date</label>
            <input id="txtDate" type="date" class="form-control" v-if="voucher" v-model="voucher.doc_date">
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Amount</label>
            <input id="txtamount" type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00"   v-if="voucher" v-model="voucher.amount"/>
          </div>
        </div>

        <div class="col-md-9">
        </div>

      </div>

      <div class="row">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Narration</label>
            <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
          </div>
        </div>

        <div class="col-md-6">
        </div>

        <div class="col-md-3 text-right">
          <SaveButton title="Save" @handle_save_action="perform_save_transaction" style="margin: 1em;"></SaveButton>
          <CloseButton title="Clear" @handle_close_action="clear" ></CloseButton>
        </div>

      </div>
    </div>

  </div>
  </FocusTrap>
</template>

<script>
import moment from 'moment-timezone';
import { userService } from '@/store/auth-header.js';
import { store } from '@/store/store.js'
import SaveButton from '../../../components/core/buttons/SaveButton'
import CloseButton from '../../../components/core/buttons/CloseButton'

export default {
  name: 'VaultPaymentForm',
  components: {
    CloseButton,
    SaveButton
  },
  store,
  data () {
    return {
      readonly: false,
      ledgers: [],
      voucher: JSON.parse('{"id":0,"status":"Active","type":0,"vch_no":0,"finyear":0,"vch_type":0,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":0,"remarks":"","cmp_id":0,"voucher_type":{"id":0,"name":""},"ledger":{"id":1,"group_id":1,"name":"","alias":"","open_bal":0},"list":[]}'),
      detail : JSON.parse('{"idx":0,"adjust_type":0, "ledger_id":0,"ledger":{"id":0,"status":"Active","name":"","alias":"","group":{"id":0,"name":"","alias":"","parent":0,"nature":0},"open_bal":0,"gstin":""},"remarks":"","debit":0,"credit":0}'),
    }
  },
  props: {
    myvoucher: {
      type: Object,
      default: () => JSON.parse('{"id":0,"status":"Active","type":0,"vch_no":0,"finyear":0,"vch_type":0,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":0,"remarks":"","cmp_id":0,"voucher_type":{"id":0,"name":""},"ledger":{"id":1,"group_id":1,"name":"","alias":"","open_bal":0},"list":[]}')
    }
  },
  beforeMount () {
    this.voucher =  JSON.parse(JSON.stringify(this.myvoucher));
  },
  created () {
    this.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
    this.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
  },
  mounted () {
    const self = this;
    try {
      self.loadCashierLedgers();

      if (self.$data.voucher.doc_date == '0001-01-01' || self.$data.voucher.doc_date == '2000-01-01') {
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');

        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
        self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
      }

      self.$data.voucher.iscredit = false;

      $('#cmbledger').focus();

    }catch (e) {
      alert(e);
    }
  },
  methods: {
    indianFormat (val) {
      return parseFloat(val).toFixed(2)
    },
    closeThis () {
      this.$emit('vaultpayment_window_closed');
      if (this.voucher.id == 0) {
        this.$router.push('/');
      }

    },
    clear () {
      const self = this;
      self.$data.detail = JSON.parse('{"idx":0,"adjust_type":0,"ledger_id":0,"remarks":"","debit":0,"credit":0}');
      self.$data.voucher = JSON.parse('{"id":0,"status":"Active","type":0,"vch_no":0,"finyear":0,"vch_type":0,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":0,"remarks":"","cmp_id":0,"voucher_type":{"id":0,"name":""},"ledger":{"id":1,"group_id":1,"name":"","alias":"","open_bal":0},"list":[]}');
      self.$data.voucher.list = [];
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
    },
    loadCashierLedgers(){
      const self = this;

      const requestOptions = {
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#vaultpayment_card').block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      // fetch the Ledgers
      self.$data.ledgers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/cashier_ledgers`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            resp.data.forEach(function (ldgr) {
              self.$data.ledgers.push(ldgr);
            });
          }
        } else {
          swal({ title: 'Oops', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes', text: err.toString(), type: 'error' })
      }).finally(function () {
        $('#vaultpayment_card').unblock();
      });
    },
    loadData () {
      const self = this;

      const requestOptions = {
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#vaultpayment_card').block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      // fetch the Ledgers
      self.$data.ledgers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/cashiers/active`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            resp.data.forEach(function (ldgr) {
              self.$data.ledgers.push(ldgr);
            });
          }
        } else {
          swal({ title: 'Oops', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes', text: err.toString(), type: 'error' })
      }).finally(function () {
        $('#vaultpayment_card').unblock();
      });
    },
    perform_save_transaction () {
      const self = this;

      try {

        // self.$data.voucher.ledger_id = self.$data.detail.ledger_id;
        self.$data.voucher.amount =  parseFloat( self.$data.voucher.amount);
        if(self.$data.voucher.ledger_id < 1) {
          $('#cmbledger').focus();
          alert('Invalid Ledger');
          return;
        }else if( parseFloat(self.$data.voucher.amount) <= 0 ){
          $('#txtamount').focus();
          alert('Invalid Amount');
          return;
        }

        let myvoucher = JSON.parse(JSON.stringify(self.$data.voucher));
        myvoucher.cmp_id = self.$store.state.user.company.id;
        myvoucher.brn_id = self.$store.state.user.branch.id;
        myvoucher.finyear = self.$store.state.user.finyear;
        myvoucher.vch_type = 1005;
        myvoucher.iscredit = false;
        myvoucher.type = 2; //Debit Transaction
        myvoucher.doc_date = moment(myvoucher.doc_date).format('YYYY-MM-DD');
        myvoucher.ref_date = moment(myvoucher.doc_date).format('YYYY-MM-DD');

        self.$data.detail.credit = 0.0;
        self.$data.detail.debit = parseFloat(self.$data.voucher.amount);

        //Debit In Cash & Credit In Paid To Ledger
        myvoucher.list = [];
        self.$data.detail.ledger_id = 100;
        myvoucher.list.push(self.$data.detail);

        const requestOptions = {
          method: ((myvoucher.id == 0) ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(myvoucher)
        }

        console.log(JSON.stringify(myvoucher));

        $('#vaultpayment_card').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/simplevoucher`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            self.clear();
            swal({ title: 'Success', type: 'success', text: resp.msg, onClose: () => { $('#cmbledger').focus()}, timer:1500  });
            self.$emit('vaultpayment_saved', resp.data);
          } else {
            swal({ title: 'Oops', text: resp.msg, type: 'error', onClose: () => { $('#cmbledger').focus()}, timer:3000  });
          }
        }).catch(function (err) {
          swal({ title: 'Oops', text: err.toString(), type: 'error', onClose: () => { $('#cmbledger').focus()}, timer:3000  });
        }).finally(function () {
          $('#vaultpayment_card').unblock();
        });

      }catch (e) {
        alert(e);
      }
    },
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
